<template>
  <edit-template @confirm="handleSubmit" :confirmLoading="loading">
    <el-form class="pl-5 pt-2" ref="form" :model="formData" :rules="rules" label-position="left" label-width="110rem" >
      <template v-if="id">
        <el-form-item label="参考城市">{{ info.city_name }}</el-form-item>
        <el-form-item label="入学年份">{{ formData.year }}</el-form-item>
      </template>
      <template v-else>
        <el-form-item label="参考城市" prop="city">
          <el-cascader v-model="formData.city" :options="areaOptions"></el-cascader>
        </el-form-item>
        <el-form-item label="入学年份" prop="year">
          <el-select v-model="formData.year">
            <el-option v-for="i in yearOptions" :key="i.value" :label="i.label" :value="i.value" />
          </el-select>
        </el-form-item>
      </template>
      <div class="mt-5">
        <div class="z-form-title">配置考试科目</div>
        <div class="mb-2">
          <template v-if="formData.subjects.length">当前已经已配置{{ formData.subjects.length }}个科目</template>
          <template v-else>暂未配置</template>
        </div>
        <el-button type="primary" @click="toggleShow">{{ formData.subjects.length ? '点此编辑' : '添加考试科目' }}</el-button>
        <div class="mt-2" v-if="isSubjectError">
          <el-alert title="请配置考试科目" type="error" :closable="false"></el-alert>
        </div>
      </div>
    </el-form>
    <Dialog :visible.sync="visible" width="1000rem" title="配置考试科目" submitText="保存" :beforeSubmit="onBeforeDialogSubmit">
      <div class="flex-center z-no-margin-bottom">
        <el-form :model="dynamicData" ref="dynamicForm" label-width="110rem">
          <div class="z-dynamic-list-item" v-for="(domain, index) in dynamicData.subjects" :key="domain.id">
            <div class="z-dynamic-list-inner">
              <el-form-item label="考试科目名称" :prop="`subjects.${index}.subject_id`" :rules="dynamicSubject">
                <el-select v-model="domain.subject_id" placeholder="请选择" filterable>
                  <el-option v-for="item in subjectOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="单科满分" :prop="`subjects.${index}.score`" :rules="dynamicNumber">
                <el-input v-model="domain.score" type="number" placeholder="请输入"></el-input>
              </el-form-item>
            </div>
            <el-form-item label-width="40rem">
              <el-button icon="el-icon-delete" v-if="dynamicData.subjects.length !== 1" circle @click.prevent="handelRemove(domain)"></el-button>
              <el-button icon="el-icon-plus" circle v-if="dynamicData.subjects.length !== maxlength && index === dynamicData.subjects.length - 1" @click="handleAdd"></el-button>
            </el-form-item>
          </div>
        </el-form>
      </div>
    </Dialog>
  </edit-template>
</template>

<script>
import Dialog from '@/components/common/Dialog'
import { validateNumber } from '@/utils/validate'
import { getAreaDataMixin, getSubjectDataMixin } from '@/utils/mixins'
import { zsPost, zsGet, getAdjacentYears } from '@/api'
import { transformAddOrEditData } from '@/utils'
export default {
	_config:{"route":[{"path":"edit","meta":{"title":"编辑"}}, {"path":"add","meta":{"title":"新增"}}]},
  components: { Dialog },
  mixins: [getAreaDataMixin, getSubjectDataMixin],
  data() {
    const ESValidateSubject =  (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请选择'))
      } else {
        const list = this.dynamicData.subjects.map(item => item.subject_id)
        let result = list.filter(item => item === value)
        if(result.length > 1) callback('考试科目名称有重复，请更正后提交')
        callback()
      }
    }
    return {
      id: '',
      yearOptions: [],
      loading: false,
      visible: false,
      isSubjectError: false,
      maxlength: 20,
      dynamicData: { subjects: [] },
      formData: { city: '', year: '', subjects: [] },
      dynamicSubject: { required: true, validator: ESValidateSubject, trigger: 'change' },
      dynamicNumber: { required: true, validator: (_, value, callback) => validateNumber(value, callback, { min: 0, max: 10000, equalToMin: true, equalToMax: true }), trigger: 'blur' },
      rules: {
        city: [{ required: true, message: '请选择参考城市', trigger: 'change' }],
        year: [{ required: true, message: '请选择入学年份', trigger: 'change' }]
      },
      info: { city_name: '' }
    }
  },
  created() {
    if(this.$route.query.id) {
      this.id = +this.$route.query.id
    } else {
      getAdjacentYears().then(res => {
        this.yearOptions = res
      })
    }
    this.getConfig()
    this.getData()
  },
  methods: {
    getConfig() {
      this.getAreaData()
      this.getSubjectData()
    },
    getData() {
      if(!this.id) return this.dynamicData.subjects.push({ id: Date.now(), subject_id: '', score: '', isAdd: 1 })
      zsGet('/recruit-2024/exam/get-diag-subject-conf', { id: this.id }).then(res => {
        const { city_name, year, city_code, diag_subject_conf = [] } = res
        this.info.city_name = city_name
        this.formData.city = ['', city_code]
        this.formData.year = year
        const list = diag_subject_conf.map(i => ({ id: i.id, subject_id: i.subject_id, score: i.score }))
        this.formData.subjects = list
        this.dynamicData.subjects = list
      })
    },
    toggleShow() {
      this.visible = true
      if(this.formData.subjects.length) {
        this.dynamicData.subjects = this.formData.subjects.map(i => ({...i}))
      }
    },
    handleAdd() {
      if(this.maxlength === this.dynamicData.subjects.length) {
        return this.$message.warning(`最多只能添加${this.maxlength}条`);
      }
      this.dynamicData.subjects.push({ id: Date.now(), subject_id: '', score: '', isAdd: 1 })
    },
    handelRemove(item) {
      const index = this.dynamicData.subjects.indexOf(item)
      if (!~index) return
      this.dynamicData.subjects.splice(index, 1);
    },
    onBeforeDialogSubmit(done) {
      this.$refs['dynamicForm'].validate(valid => {
        if(valid) {
          const list = this.dynamicData.subjects.map(i => ({...i}))
          if(!list.length) return this.$message.warning('请需配置数据')
          this.isSubjectError = false
          this.formData.subjects = list
          done()
        }
      })
    },
    handleSubmit() {
      this.$refs['form'].validate(valid => {
        if(valid) {
          const { subjects, city, year } = this.formData
          if(!subjects.length) return this.isSubjectError = true
          const data = { city_code: city[1], year, diag_subject_conf: transformAddOrEditData(subjects) }
          if(this.id) data.id = this.id
          const api = `/recruit-2024/exam/${this.id ? 'edit' : 'add'}-diag-subject-conf`
          this.loading = true
          zsPost(api, data).then(() => {
            this.$message.success(`${this.id ? '编辑' : '新增'}成功`)
            this.$router.back()
          }).finally(() => this.loading = false)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
